<template>
    <v-col cols="12" class="pt-0 px-0">
        <v-card min-width="100%" class="mx-auto mb-6">
            <v-toolbar :color="color" :style="`color: ${textColor}`" class="mb-5 scan-title-bar" elevation="3">
                <v-toolbar-title class="text-section-title">Scan Video's</v-toolbar-title>
            </v-toolbar>
            <div class="d-flex pa-5 justify-center" v-if="scanLoading">
                <template v-for="(item, index) in Array.from(Array(11).keys())">
                    <v-skeleton-loader :key="index" style="width: 100px; height: 100px" v-bind="{ class: 'mx-2', elevation: 1 }"
                        type="image">
                    </v-skeleton-loader>
                </template>
            </div>
            <template v-if="!scanLoading && videoList.length == 0">
                <div class="ma-0 pa-5 pb-7 text-center">
                    Video's Not Available!.
                </div>
            </template>
            <v-sheet elevation="0" class="d-flex flex-wrap pa-0" v-if="!scanLoading && videoList">
                <v-slide-group mandatory show-arrows >
                    <v-slide-item v-for="(item, index) in videoList" :key="index">
                        <div class="mx-2 mb-3 position-relative cursor-pointer" style="width: 100px; height: 100px;" @click="playVideo=index">
                            <v-icon size="30" color="white" class="video-play-icon">mdi-play-circle</v-icon>
                            <v-img :src="item.thumbnail" width="100" height="100" crossorigin="anonymous" />
                        </div>
                    </v-slide-item>
                </v-slide-group>
            </v-sheet>
             <VideoPlay v-if="playVideo != null" :lists="videoList" :videoIndex="playVideo" @close="playVideo=null" @save="$emit('saved', $event)"/>
        </v-card>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props:['videoList', 'imgVideoLists', 'color', 'textColor'],
    emits: ['saved'],
    components: {
        VideoPlay: () => import("./VideoPlyModal")
    },
    data: (vm) => ({
        playVideo: null
    }),
    computed: {
        ...mapGetters(['scanLoading'])
    },
}
</script>

<style>

</style>